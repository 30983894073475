import { useCallback } from "react";

import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { fetchProjectById } from "shared/api/client";

import { Project } from "types";

export const useGetProjectById = (projectId: string, isEnabled?: boolean) => {
  const fetchProject = useCallback(
    () => fetchProjectById(projectId),
    [projectId]
  );

  const project = useQuery<Project, AxiosError>(
    ["project", projectId],
    fetchProject,
    {
      enabled: isEnabled,
    }
  );

  return project;
};
